import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate, useParams } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { columns } from "../../components/shared/admin/components/Table/columns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";



const ProductsAdmin = () => {
  const [productByCategory, { isSuccess, isError, error,isLoading, data: products }] = useGetAllProductsMutation()
  //const [categoryId,setCategoryId]=useState<number>(2)
  const navigate=useNavigate()
  const {catId}=useParams() ?? 1;


  //useEffect(()=>{setCategoryId(Number(catId))},[catId])

  useEffect(() => {
    productByCategory({ catId })
  }, [productByCategory,catId])

  /* useEffect(() => {
    productByCategory({ catId: categoryId })
  }, [productByCategory,categoryId]) */

  //md:max-w-[700px]  lg:max-w-[800px] xl:max-w-[900px] 2xl:max-w-[1200px]
  let content;

  if (isLoading) {
    content = (
        <div className="flex justify-center items-center h-full my-6">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
    );
}

  if (isError) content = <p>{products?.result?.message}</p>

  if (isSuccess){
    content=  <section className="md:ml-52">
    <main className=" border-2  bg-green-50  border-gray-500 md:mx-8 md:my-12 md:p-4">
       <div className="flex justify-between p-4">
        <h5 className='h5-bold'>Products</h5>
        {/* <FilterByCategory onChangeHandler={setCategoryId} categoryId={categoryId}/> */}
        <FilterByCategory  categoryId={catId!}/>
        <Button className="headermenu" onClick={()=>navigate("/admin/dashboard/newproduct")}>Add New Product</Button>
      </div>
      {products?.result.length>0?<div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {products?.result?.length>0 && <DataTable data={products?.result} columns={columns}/>}
      </div>:<p className=" p-4 rounded-md border-2  md:overflow-hidden shadow-lg bg-green-50">
        No Products Available Under Selected Category
      </p>}
    </main>
    </section>
  }

  return <>{content}</>;
}

export default ProductsAdmin

 export const FilterByCategory = ({categoryId }:{ categoryId:string}) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const navigate=useNavigate()
  const handleValueChange = (selectedValue: string) => {
    navigate(`/admin/dashboard/products/${selectedValue}`)
  };

  return (
    <Select onValueChange={handleValueChange} value={categoryId.toString()}>
      <SelectTrigger className="bg-green-50 text-md font-semibold px-0">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; 
/* export const FilterByCategory = ({ onChangeHandler,categoryId }: any) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const navigate=useNavigate()
  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
    navigate(`/admin/dashboard/products/${selectedValue}`)
  };

  return (
    <Select onValueChange={handleValueChange} value={categoryId.toString()}>
      <SelectTrigger className="bg-green-50 text-md font-semibold px-0">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; */