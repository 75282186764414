import Weaves from '../components/shared/Home/Weaves'
import Video from '../components/shared/Home/Video'
import ShopByPrice from '../components/shared/Home/ShopByPrice'
import Explore from '../components/shared/Home/Explore'
import NewCollection from '../components/shared/Home/NewCollection'
import Download from '../components/shared/Home/Download'
import MyChatBot from '../components/shared/Home/chatbot.tsx/Chatbot'
import PagenavigationCarousel from '../components/shared/Home/PagenavigationCarousel'
import PopularProducts from '../components/shared/Home/PopularProducts'
import { Button } from '../components/ui/button'
import { IoLogoWhatsapp } from 'react-icons/io5'
import { FaSquareWhatsapp } from 'react-icons/fa6'

const Home = () => {
  const shareOnWhatsApp = () => {
    const phoneNumber = '8866886623'; // The phone number you want to chat with
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  };
  return (
    <>
    <PagenavigationCarousel/>
    <NewCollection />
    <Weaves/>
    <PopularProducts/>
    <ShopByPrice/>
    <Explore/>
    <div className="fixed bottom-4 right-4">
        <div onClick={shareOnWhatsApp} className='w-12 h-12 bg-white rounded-full flex items-center justify-center cursor-pointer'>
          <IoLogoWhatsapp  color="green" size={"40"} /></div>
      </div>
    {/* <MyChatBot/> */}
    </>
  )
}


export default Home