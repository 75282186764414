import { Button } from "../../components/ui/button";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetActiveOrdersAQuery, useGetOrdersByDateAMutation } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumns } from "../../components/shared/admin/components/OrdersTable/OrderColumns";
import { CalendarIcon } from "@radix-ui/react-icons"
import { addDays, format, subDays } from "date-fns"
import { Calendar } from "../../components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover"
import { useEffect, useState } from "react";
import { cn } from "../../lib/utils"
import { Label } from "../../components/ui/label";


const DateRangeOrders = () => {
  const [getOrdersByDate, { isSuccess, isError, error, isLoading, data: getAllOrders }] = useGetOrdersByDateAMutation()
 /*  const [date, setDate] = useState<DateRange | undefined>({
    from: subDays(new Date(), 1),
    to: new Date()
  }) */

    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 1))
    const [endDate, setEndDate] = useState<Date>(new Date())



  useEffect(() => {
    if (startDate && endDate ) {
      const formattedDates = {
        startDate: startDate.toLocaleDateString('en-GB'), // 'en-GB' for DD/MM/YYYY format
        endDate: endDate.toLocaleDateString('en-GB')
      };
      console.log(formattedDates);
      getOrdersByDate(formattedDates)
    }

  }, [getOrdersByDate, startDate,endDate])
  
  let DateRange = <div className="flex gap-10">
  <div className="flex gap-4 items-center">
<Label>Start Date:</Label>
  <DatePicker date={startDate} setDate={setStartDate}/>
  </div>
  <div className="flex gap-4 items-center">
<Label>End Date:</Label>
  <DatePicker date={endDate} setDate={setEndDate}/>
  </div>
  </div>
  let content
  if (isLoading) {
    content = (
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }
  if (isError) content = <p>{getAllOrders?.result?.message}</p>
  if (!getAllOrders?.result?.length) {
    content = <div className="p-4 flex justify-between flex-wrap gap-10  text-lg font-semibold w-full">
      NO ORDERS PLACED ON SELECTED RAGNE DATE
     {DateRange}
    </div>
  }
  if (isSuccess && getAllOrders?.result?.length > 0) {
    content = <>
      <div className="flex justify-between  flex-wrap gap-10 p-4">
        <h5 className='h5-bold'>ORDERS BY DATE</h5>
       {DateRange}
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {getAllOrders?.result?.length > 0 && <DataTable data={getAllOrders?.result} columns={orderColumns} />}
      </div>
    </>
  }
  return (<section className="md:ml-52">
    <main className=" border-2  bg-green-50  border-gray-500 md:mx-8 md:my-12 md:p-4">
      {content}
    </main>
  </section>)
}




export function DatePicker({date,setDate}:any) {
 
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}


export default DateRangeOrders
