import { apiSlice } from "../../apiSlice";

export const ACreateOrderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrder: builder.mutation({
      query: (initialProdData) => ({
        url: "/common/createOrder",
        method: "POST",
        body: { ...initialProdData },
      }),
      invalidatesTags: [{ type: "Order", id: "LIST" }],
    }),
    saveOrderA: builder.mutation({
      query: (initialorderData) => ({
        url: "/admin/saveOrder",
        method: "POST",
        body: { ...initialorderData },
      }),
      invalidatesTags: [{ type: "Order", id: "LIST" }],
    }),
    updateOrderStatusA: builder.mutation({
      query: (orderData) => ({
        url: "/admin/updateOrderStatus",
        method: "POST",
        body: { ...orderData },
      }),
      invalidatesTags: [{ type: "Order", id: "LIST" }],
    }),
    getActiveOrdersA: builder.query({
      query: () => ({ url: `/admin/getActiveOrders` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Order", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Order", id })),
          ];
        } else return [{ type: "Order", id: "LIST" }];
      },
    }),
    getCancelledOrdersA: builder.query({
      query: () => ({ url: `/admin/getCancelledOrders` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Order", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Order", id })),
          ];
        } else return [{ type: "Order", id: "LIST" }];
      },
    }),
    getClosedOrdersA: builder.query({
      query: () => ({ url: `/admin/getClosedOrders` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Order", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Order", id })),
          ];
        } else return [{ type: "Order", id: "LIST" }];
      },
    }),
    getOrdersByCustomer: builder.query({
      query: (id) => ({ url: `/common/getOrdersByCustomer/${id}` }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Order", id: "LIST" },
            ...result.ids.map((id: any) => ({ type: "Order", id })),
          ];
        } else return [{ type: "Order", id: "LIST" }];
      },
    }),
    cancelOrderCustomer: builder.mutation({
      query: (cancelId) => ({
        url: `/common/cancelOrderCustomer`,
        method: "POST",
        body: { ...cancelId },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Order", id: arg.id }],
    }),
    getOrdersByDateA: builder.mutation({
      query: (cancelId) => ({
        url: `/orders/getOrdersByDate`,
        method: "POST",
        body: { ...cancelId },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Order", id: arg.id }
      ],
    }),
    updateOrderCourierInfo: builder.mutation({
      query: (courierInfo) => ({
        url: `/admin/updateOrderCourierInfo`,
        method: "POST",
        body: { ...courierInfo },
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Order", id: arg.id }],
    }),
    deleteOrderA: builder.mutation({
      query: (id) => ({
        url: `/admin/deleteOrder/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [{ type: "Order", id: arg.id }],
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useDeleteOrderAMutation,
  useGetActiveOrdersAQuery,
  useGetClosedOrdersAQuery,
  useCancelOrderCustomerMutation,
  useGetCancelledOrdersAQuery,
  useGetOrdersByCustomerQuery,
  useSaveOrderAMutation,
  useUpdateOrderStatusAMutation,
  useUpdateOrderCourierInfoMutation,
  useGetOrdersByDateAMutation
} = ACreateOrderApiSlice;
