import * as React from "react";

import { cn } from "../../../lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../ui/navigation-menu";
import { subHeaderLinks } from "../../../constants/index";
import { Link } from "react-router-dom";
import { BsFillSuitDiamondFill } from "react-icons/bs";
import { useGetAllSubcategoryQuery } from "../../../redux/slice/CategoryApiSlice";


export function SubNavigation() {
  const { data: AllSubCategories } = useGetAllSubcategoryQuery("subCategoriesList", { pollingInterval: 60000, refetchOnFocus: true, refetchOnMountOrArgChange: true, })


    return (
      <NavigationMenu>
        <NavigationMenuList className="flex md:flex-row flex-wrap md:gap-4 flex-col max-md:items-start">
          {subHeaderLinks.map((links,idx) => {
            return (
              <React.Fragment key={links.label}>
                 {AllSubCategories?.result?.length>0 ? 
                ( <NavigationMenu>
                  <NavigationMenuItem className="relative">
                    <NavigationMenuTrigger className="hover:text-green-600 md:text-green-900">
                      {links.label}
                    </NavigationMenuTrigger>
                   {/* {<span className="max-md:hidden"><BsFillSuitDiamondFill size={"16"}/></span>}  */}
                    <NavigationMenuContent>
                      <ul className=" grid w-[300px] gap-1 p-4 md:w-[400px] md:grid-cols-2 ">
                      <ListItem
                      key={`category-${links.categoryId}`}
                      href={`/category/${links.categoryId}`}
                      className="font-bold text-green-600"
                    >
                      {`${links.label}`}
                    </ListItem>
                        {AllSubCategories?.result?.filter((sub:any)=>sub.categoryId===links.categoryId).map((link:any) => (
                          <ListItem
                            key={`${link.categoryId}-${link.id}`}
                            href={`/subcategory/${link.id}`}
                          >
                            {link.subcategoryName}
                          </ListItem>
                        ))}
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                  </NavigationMenu> 
                ):
                   <NavigationMenuItem key={links.route}>
                    <Link to={links.route} className={`${navigationMenuTriggerStyle()} hover:text-green-600 lg:text-green-900 flex gap-4 items-center`}>{idx!==0&&<span className="mr-2 max-md:hidden"><BsFillSuitDiamondFill size={"16"}/></span>} {links.label}
                    </Link>
                  </NavigationMenuItem> }
              </React.Fragment>
            );
          })}
          <NavigationMenuItem>
                    <Link to={`/shopbyprice/10000`} className={`${navigationMenuTriggerStyle()} hover:text-green-600 lg:text-green-900 flex gap-4 items-center`}>BUDGET SAREES
                    </Link>
                  </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    );
  }


  const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {

  
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-[2px] rounded-md p-2 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none ">{title}</div>
          <p className="line-clamp-2 text-sm text-green-800">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})

ListItem.displayName = "ListItem"