import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog"
import { FaPlus } from "react-icons/fa6";
import { Button } from "../../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form"
import { Input } from "../../../components/ui/input"
import { useDeleteCustomerAddressMutation, useSaveCustomerAddressMutation } from "../../../redux/slice/ACustomerAddressApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { useGetCustomerQuery } from "../../../redux/slice/UserApiSlice"

const formSchema = z.object({
    fullName: z.string().min(3, {
        message: 'FullName must be at least 3 characters.',
      }),
    hno: z.string().min(3, {
        message: 'House no must be at least 3 characters.',
      }),
    street: z.string().min(3, {
        message: 'strre must be at least 3 characters.',
      }),
    city: z.string().min(3, {
        message: 'city must be at least 3 characters.',
      }),
      pincode: z.string().min(3, {
        message: 'Pincode must be at least 3 characters.',
      }),
   /*    pincode: z.string().length(6, {
        message: 'Pincode must be exactly 6 characters.',
      }), */      
    state: z.string().min(2, {
        message: 'State must be at least 2 characters.',
      }),
    country: z.string().min(3, {
        message: 'Country must be at least 3 characters.',
      }),
      /* ,
      email: z.string().email({
        message: 'Invalid email format.',
      }), */
      mobile: z.string().refine((value) => /^\d{10}$/.test(value), {
        message: 'Mobile number must be exactly 10 digits.',
      }),
})

const initialValuesNew={
  fullName: '',
    mobile: '',
    hno: '',
    street:  '',
    city: '',
    pincode:  '',
    state: '',
    country: 'India',
}
function CustomerInfo({ user,address,type,id,reftechUser }: any) {
  const navigate=useNavigate()
  const [deleteAddress,{data:delAddData}]=useDeleteCustomerAddressMutation()
  const [saveCustomerAddress,{data}]=useSaveCustomerAddressMutation()
  const [open,setOpen]=useState(false)
  const initialValues = type==="Create"? initialValuesNew : JSON.parse(address)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
  });

  const handleClose=()=>{
    setOpen(false)
  }

  useEffect(()=>{
    if(data?.success||delAddData?.success){
      setOpen(false)
      reftechUser()
      navigate("/checkout")
    }
  },[data,navigate,reftechUser,delAddData])
  
  const handleDeleteAddress=async()=>{
   await deleteAddress(id)
  }
  
  async function onSubmit(values: z.infer<typeof formSchema>) {
  const addressDetails = {
    hno: values.hno,
    street: values.street,
    city: values.city,
    pincode: values.pincode,
    state: values.state,
    country: values.country,
    mobile: values.mobile,
    fullName: values.fullName
  };

  if(type==="Edit"){
    //console.log({ address: JSON.stringify(addressDetails),id,customerId: user?.id })
    const response = await saveCustomerAddress({ address: JSON.stringify(addressDetails),id,customerId: user?.id,isPrimary:0});
    console.log("API response:", response);
  }else{
    const response = await saveCustomerAddress({ address: JSON.stringify(addressDetails), customerId: user?.id });
    console.log("API response:", response);
  }  
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger className={`underline text-red-800 text-md font-semibold flex items-center gap-1`}>
        {type==="Create"&& <FaPlus />}{type==="Create"?"Add New Address":"Edit Address"}
      </DialogTrigger>
      <DialogContent className="overflow-y-scroll max-h-screen">
      <p className="text-md font-semibold">{type==="Create"?"Enter a new delivery address":"Edit previous Address"}</p>
        <p className="text-red-400 text-sm font-semibold">All fields are required*</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>FullName</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="FullName" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Mobile</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Mobile no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex md:flex-row flex-col gap-2">
          <FormField
          control={form.control}
          name="hno"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>House No</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="House no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Street</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Street" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        </div>
        <div className="flex md:flex-row flex-col gap-2">
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="City" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="pincode"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Pincode</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Pincode" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        </div>
        <div className="flex md:flex-row flex-col gap-2">
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="State" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Country" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        /></div>
          <div className="flex justify-between">
          <Button type="submit" className="headermenu">{type==="Edit"?"Edit Address" :"Add New Address"}</Button>
          {type==="Edit"?<Button type="button"  onClick={handleDeleteAddress} className="headermenu">Delete Address</Button>:<Button type="button"  onClick={handleClose} className="headermenu">Cancel</Button>}
            </div>    
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CustomerInfo;
