import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import CartDetails, { CartDetailsAPI } from "../components/shared/Detail/CartDetails";
import { Button } from "../components/ui/button";
import { cartProduct } from "@/react-app-env";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
import {  useGetShoppingCartByCustomerQuery } from "../redux/slice/CartApiSlice";
import { useNavigate } from "react-router-dom";

const Cart = () => {
   const currentUser=JSON.parse(localStorage.getItem("sskiuser")!);
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const { currency } = useSelector((state: RootState) => state.counterCurrency);
  const { data: cartItemsAPI, isError, isLoading, isSuccess, error} = useGetShoppingCartByCustomerQuery(currentUser?.refId,{refetchOnFocus:true,refetchOnMountOrArgChange:true})

  const navigate=useNavigate()
  const filterReadyToBuy=cartItemsAPI?.result.length>0 && cartItemsAPI?.result.filter((product:cartProduct)=>product.readyToBuy===1) 
  const taxAmount =cartItemsAPI?.result.length>0? ( currency==="USD"? filterReadyToBuy.length*1:filterReadyToBuy.length*50):( currency==="USD"? cartItems.length*1:cartItems.length*50)
   const totalPrice =cartItemsAPI?.result ? (cartItemsAPI?.result?.reduce((acc:number, product:cartProduct) => {
        const productTotal =product?.readyToBuy===1?( currency==="USD"?product.priceUSD:product.priceINR) * product.quantity:0;
        return acc + productTotal;
      }, 0)):(cartItems?.reduce((acc, product) => {
        const productTotal =( currency==="USD"?product.priceUSD:product.priceINR) 
        return acc + productTotal;
      }, 0))


  return (
    <div className="bgcolorgold ">
      <div  className="wrapper-weaves my-4">
      <div className="flex md:justify-between md:flex-row flex-col gap-4 ">
        <div className="shadow-lg  px-2 bg-gray-100 w-full font-display">
          <h5 className="h5-bold">Shopping Cart</h5>
          <div className="flex justify-between px-2 items-center border-b-2 pb-3 border-gray-200">
            <p>Your Items</p>
            <p>price</p>
          </div>
          <div className="p-2 flex flex-col gap-3">
            { cartItemsAPI?.result?.length>0 ?  cartItemsAPI?.result?.map((item:cartProduct) => (
                <CartDetailsAPI key={item.id} saree={item} />
              )): (cartItems.length > 0 ? (
              cartItems.map((item) => (
                <CartDetails key={item.id} saree={item} />
              ))
            ) : (
              <p className="text-center text-lg font-semibold">No cart Items Found</p>
            ))}
          </div>
        </div>
        {totalPrice > 0  && <div className="shadow-lg p-4 bg-purple-200 md:w-[350px] w-full h-[250px] text-sm  flex flex-col gap-8 ">
         <div className="flex justify-between gap-4">
          <p>Subtotal</p>
          {/* <p className="flex items-center">{currency === "USD" ?<BsCurrencyDollar size={14} />:<MdCurrencyRupee size={14} />} {totalPrice}</p> */}
          <p className="flex items-center">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(totalPrice)}</p>
         </div>
         {/* <div className="flex justify-between gap-4">
          <p>Savings</p>
          <p className="flex items-center">{currency === "USD" ?<><BsCurrencyDollar size={14} />1</>:<><MdCurrencyRupee size={14} />50</>}</p>
         </div> */}
         <div className="flex justify-between gap-4">
          <p>Shipping</p>
{/*           <p className="flex items-center">{currency === "USD" ?<><BsCurrencyDollar size={14} />{taxAmount}</>:<><MdCurrencyRupee size={14} />{taxAmount}</>} </p> */}
          <p className="flex items-center">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(taxAmount)}</p>
         </div>
         <div className="flex justify-between gap-4 text-md font-semibold">
          <p>Total Price</p>
          {/* <p className="flex items-center">{currency === "USD" ?<><BsCurrencyDollar size={14} />{totalPrice+taxAmount}</>:<><MdCurrencyRupee size={14} />{totalPrice+taxAmount}</>}</p> */}
          <p className="flex items-center">{ new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(totalPrice+taxAmount)}</p>

         </div>
         <Button onClick={()=>{navigate("/checkout")}} className="headermenu">Proceed to buy</Button>
        </div>}
      </div>
     </div>
    </div>
  );
};

export default Cart;
