import ReactQuill from 'react-quill-new';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }], [{'size': []}],[{'color': []}],[{'background': []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image'],
    ['clean']
  ],
}

const formats = [
  'header','font','size','color','background',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list',  'indent',
  'link', 'image'
]

// 'list', 'bullet', 'indent',
const ProductDescriptionNew = ({onChangeHandler,value}:any) => {
  return (<div className='h-full'>

    <ReactQuill theme="snow" value={value} onChange={onChangeHandler}
          modules={modules} formats={formats} className='h-40' />
  </div>
  )
}

export default ProductDescriptionNew