
export const APIURL= "https://vilacampus.in/sakhiapi/"
export const IMGURL= "https://vilacampus.in/sakhiapi/static/"


// export const APIURL= "http://localhost:3500/sakhiapi/"
// export const IMGURL= "http://localhost:3500/sakhiapi/static/"


// export const APIURL= "https://sakhisarees.com/sakhiapi/"
// export const IMGURL= "https://sakhisarees.com/sakhiapi/static/"


export const RECAPCHASITEKEY= "6LegAHwpAAAAAF862qcoUDVCUVzHWD2ZyH1kAdn4"
export const RECAPCHASECRETKEY= "6LegAHwpAAAAANpkP4B5dNz0mTZRYi2jEWUIsh4b"
export const CALENDLYURL= "https://calendly.com/ssinfodetails/sakhi-sarees"