const About = () => {
  return (
    <div className="bgcolorgold">
      <div className="wrapper flex flex-col gap-4">
        <div>
          <p className="p-semibold-20">
            Welcome to Sakhi
          </p>
          <p className="p-regular-16">
            At Sakhi, we believe in the timeless
            allure of silk sarees and the stories they weave. Nestled in the
            heart of Hyderabad, we are a haven for connoisseurs of fine silk,
            offering a curated collection that combines tradition with
            contemporary elegance.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Our Heritage:</p>
          <p className="p-regular-16">
            Rooted in a rich legacy of 20+ years, Sakhi is not just a store; it's a celebration of craftsmanship,
            heritage, and the enduring beauty of silk. Our journey began with a
            passion for preserving and promoting the artistry of silk weaving,
            and over the years, we have evolved into a destination where every
            silk saree tells a tale of artistry and finesse.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">The Artisans:</p>
          <p className="p-regular-16">
            Central to our ethos are the skilled artisans who breathe life into
            each silk saree. Hailing from traditional weaving clusters across
            country, these master craftsmen and craftswomen infuse their
            expertise into every weave, ensuring that each saree is a
            masterpiece in itself.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Our Collection:</p>
          <p className="p-regular-16">
            Explore our meticulously curated collection, showcasing a spectrum
            of silk sarees ranging from timeless classics to contemporary
            designs. Whether you seek the regality of Banarasi silk, the
            intricacy of Kanjeevaram, or the subtle charm of Tussar silk, our
            diverse range caters to every discerning taste.
          </p>
        </div>
        <div>
          <p className="p-semibold-20">Commitment to Quality:</p>
          <p className="p-regular-16">
            Quality is at the forefront of everything we do. Each saree
            undergoes rigorous quality checks to ensure that it not only meets
            but exceeds your expectations. We take pride in offering you not
            just a product, but a piece of art that you can cherish for
            generations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
