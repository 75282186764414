import { MdCurrencyRupee } from "react-icons/md"
import { Button } from "../../../ui/button"
import { useState } from "react"
import ImagesCol from "../../Detail/ImagesCol"
import 'react-toastify/dist/ReactToastify.css';
import ReactHtmlParser from 'html-react-parser';
import { IMGURL } from "../../../../constants/appConfig"
import { ProductWithImages } from "@/react-app-env"
import { useNavigate } from "react-router-dom"

const ViewProduct = ({ saree }: { saree: ProductWithImages }) => {
  let images = [...saree.images]
  // let images = [{productId:saree?.id,id:"thumbnail",imageUrl:saree?.thumbnail},...saree.images]
  let imageslength = images.length
  const [imagesCount, setImagesCount] = useState<number>(0)
  const [imgCK, setImgCk] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6  wrapper-weaves">
      <div className="flex md:flex-row flex-col  gap-3 ">
        {imageslength > 0 && <ImagesCol setcount={setImagesCount} ImgCk={setImgCk} images={images} />}
        <img
          src={imgCK ? `${IMGURL}${images.filter(img => img?.id === imagesCount)[0].imageUrl}` : `${IMGURL}${images[0].imageUrl}`}
          alt={saree.productName}
          className="rounded-md w-[85%] h-[500px] "
        /></div>
      <div className="flex flex-col gap-3">
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Product SKU No:</h6>
          <p>{saree?.sku}</p>
        </div>
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Product Name:</h6>
          <p>{saree.productName}</p>
        </div>
        <div className="flex gap-4 md:flex-row flex-col">
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">Category:</h6>
            <p>{saree.categoryName}</p>
          </div>
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">SubCategory:</h6>
            <p>{saree.subcategoryName}</p>
          </div>

        </div>
        {/* <div>
          <h5 className="text-lg font-semibold">Product Description Short:</h5>
          <p>{saree.descriptionShort}</p>
        </div> */}
        <div>
          <h6 className="text-md font-semibold">Product Description Long:</h6>
          {saree.productDescription && <div> {ReactHtmlParser(saree?.productDescription)}</div>}
        </div>
        <div className=" flex lg:flex-row flex-col gap-6">
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">Discount:</h6>
            <p>{saree.discount ? saree.discount : "No"}</p>
          </div>
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">PriceINR:</h6>
            {/* <p className="flex items-center"><MdCurrencyRupee size={18} />{saree.priceINR}</p> */}
            <p className="flex items-center">{new Intl.NumberFormat('en-US', { style: 'currency', currency: "INR" }).format(saree.priceINR)}</p>
          </div>
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">PriceUSD:</h6>
            {/* <p className="flex items-center"><BsCurrencyDollar size={18} />{saree.priceUSD?saree.priceUSD:"No"}</p> */}
            {saree.priceUSD ? <p className="flex items-center">{new Intl.NumberFormat('en-US', { style: 'currency', currency: "USD" }).format(saree.priceUSD)}</p> : <p>-</p>}
          </div>
        </div>
        <div className=" flex md:flex-row flex-col gap-6">
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">Available Quantity:</h6>
            <p>{saree.availableQuantity}</p>
          </div>
          <div className="flex gap-1">
            <h6 className="text-md font-semibold">Sold Quantity:</h6>
            <p>{saree.soldQuantity}</p>
          </div>
        </div>
        <div className="flex justify-between">
          <Button onClick={() => navigate(`/admin/dashboard/edit/${saree.id}`)} className="mt-8">Edit Product</Button>
          <Button onClick={() => navigate(-1)} className="mt-8">Back to Products</Button>
        </div>
      </div>
    </div>
  )
}

export default ViewProduct