import { TCategory, TSubCategory, sareeData } from '@/react-app-env'
import React, { useEffect, useState } from 'react'
import { FaSearch } from "react-icons/fa";
import { DataTable } from './Table'
import { Input } from "../../ui/input";
import { Button } from '../../ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { useNavigate, useParams } from 'react-router-dom'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';
import { useGetAllColorsQuery } from '../../../redux/slice/CategoryApiSlice';


const columns = [
  {
    accessorKey: "saree",
    header: "saree",
  },
]
type FSTableDataProps = {
  data: sareeData[],
  subCategories?: TSubCategory[],
  categoryType?: string,
  categories?: TCategory[]
}
const FSTableData = ({ data, subCategories, categoryType, categories }: FSTableDataProps) => {
  const [filterData, SetFilterData] = useState<sareeData[]>([]);
  const [search, setSearch] = useState<string>("");
  const [fPrice, setFPrice] = useState<string>("")
  const [fPriceR, setFPriceR] = useState<number>(1000)
  const [fColor, setFColor] = useState<number>(0)
  const [showColor, setShowColor] = useState<boolean>(false)

  useEffect(() => {
    const filterDataFun = (searchTerm: string) => {
      const filtered = searchTerm.length > 0 ? data.filter((item: sareeData) =>(
        item.productName.toLowerCase().includes(searchTerm.toLowerCase()))
      ) : data;
      SetFilterData(filtered);
    };
    filterDataFun(search);
  }, [search, data]);

  useEffect(() => {
    let sortedData
    if (fPrice === "Low to high") {
      sortedData = [...data].sort((a, b) => a.priceINR - b.priceINR);
    } else {
      sortedData = [...data].sort((a, b) => b.priceINR - a.priceINR);
    }
    SetFilterData(sortedData)
  }, [fPrice, data]);

  useEffect(() => {
    let sortedData
    sortedData = [...data].filter(product=>fPriceR>product.priceINR)
    SetFilterData(sortedData)
  }, [fPriceR, data]);

  useEffect(() => {
    if(fColor){
      let sortedData=data.filter((item: sareeData)=>(item.colorId===fColor))
      SetFilterData(sortedData)
    }else{
      SetFilterData(data)
    }
  }, [fColor, data]);


  const removeFiltersHandler = () => {
    setSearch("")
    setFPrice("")
    setFColor(0)
  }

  return (<section className="bgcolorgold text-black  font-display" >
    <div className="m-2 flex">
      <div className='hidden sm:block'>
        <div className="flex  flex-col py-4 gap-4">
          <div className='flex gap-3 items-center border-b-2 my-4 border-gray-500 px-2'>
            <FaSearch size={18} className='' />
            <Input
              placeholder="Search For Sarees Name..."
              value={search}
              onChange={(event) => { setSearch(event.target.value) }}
              className='search-field'
            />
          </div>
          <div className='flex justify-start flex-col gap-4 px-3'>
            <div className='flex justify-between items-center border-b-2 border-black pb-2'><p className='text-sm font-semibold '>FILTERS</p><Button size="sm" variant="link" className='text-black' onClick={removeFiltersHandler}>CLEAR FILTERS</Button></div>
             <FilterByPriceDropdown value={fPrice} onChangeHandler={setFPrice} />
             <FilterByPriceRange value={fPriceR} onChangeHandler={setFPriceR} />
             {categoryType && <FilterBySubCategoryDropdown categoryType={categoryType} subCategories={subCategories} />}
             {categories?.length && <FilterByCategoryDropdown categories={categories} />}
             <div onClick={()=>setShowColor(pre=>!pre)} className='flex justify-between items-center text-sm font-semibold hover:cursor-pointer'><span className='flex gap-2'>FILTER BY COLOR</span>{showColor?<FaChevronUp  size={16} />:<FaChevronDown size={16} />} </div>
            {showColor&&<FilterByColor onChangeHandler={setFColor}/>}
          </div>
        </div></div>
      {filterData.length >= 0 ? <DataTable data={filterData} columns={columns} /> : <DataTable data={data} columns={columns} />}
    </div>
  </section>);
};

export default FSTableData;


export const FilterByPriceDropdown = ({ onChangeHandler, value }: any) => {
  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };

  return (
    <Select onValueChange={handleValueChange} value={value}>
      <SelectTrigger className="bgcolorgold text-sm font-semibold px-0">
        <SelectValue placeholder={`SORT BY PRICE `} />
      </SelectTrigger>
      <SelectContent className=' border-none bgcolorgold'>
        {[{id:1,value:"Low to high"},{id:2, value:"High to low"}].map((category) => (
          <SelectItem
            key={category.id}
            value={category.value}
            className="select-item p-regular-14"
          >
            {category.value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const FilterByPriceRange = ({value, onChangeHandler }: any) => {
const {price}=useParams()

  return (<>
  <div className='flex justify-between text-sm gap-2'>
  <p className='font-semibold '>PRICE RANGE UNDER</p>
  <p className='font-semibold'>{Intl.NumberFormat("en-US", {
            style: "currency",
            currency:"INR",
          }).format(value)}</p>

  </div>
    <Input
    placeholder="Search For Sarees Name..."
    type='range'
    value={value}
    min={1000}
    max={price||100000}
    onChange={(event) => { onChangeHandler(event.target.value) }}
    style={{
      width: '100%', // Set width to 100% to fill the container
      height: '2px', // Set height of the range track
     color:'brown',
    }}
  />
  </>);
};


export const FilterByColor = ({ onChangeHandler }: any) => {
  const {data:allColors} =  useGetAllColorsQuery("colorsList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
  const handleValueChange = (selectedValue: number) => {
    onChangeHandler(selectedValue);
  };
  
  return (<>
    <div className='flex gap-2 md:max-w-[220px] flex-wrap md:p-2'>
        {allColors?.result?.length>0&& allColors?.result.map((color:any) => (
          <Button
            key={color.id}
            value={color.id}
            className="select-item rounded-full w-8 h-8 shadow-lg border-[1px] border-gray-400"
            onClick={()=>handleValueChange(color.id)}
            style={{ backgroundColor: color.colorCode }}
          >
          </Button>
        ))}

    </div>
    </>);
};


export const FilterBySubCategoryDropdown = ({ subCategories, categoryType }: any) => {
 const navigate = useNavigate()
/*   const { catId } = useParams() */
  const handleValueChange = (selectedValue: string) => {
    navigate(`/subcategory/${selectedValue}`);
  };
  

  return (
    <Select onValueChange={handleValueChange}>
      <SelectTrigger className="bgcolorgold text-sm font-semibold px-0">
        <SelectValue placeholder={categoryType} />
      </SelectTrigger>
      <SelectContent className=' border-none'>
        {subCategories?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id}
            className="select-item p-regular-14"
          >
            {category.subcategoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const FilterByCategoryDropdown = ({ categories }: any) => {
  const navigate = useNavigate()
  const handleValueChange = (selectedValue: string) => {
    navigate(`/category/${selectedValue}`);
  };

  return (
    <Select onValueChange={handleValueChange}>
      <SelectTrigger className="bgcolorgold text-sm font-semibold px-0">
        <SelectValue placeholder={"CATEGORIES"} />
      </SelectTrigger>
      <SelectContent className=' border-none bgcolorgold hover:text-black '>
        {categories?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

