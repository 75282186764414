import { IoLogoYoutube } from "react-icons/io";
import { FaInstagram, FaPinterestP } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { Separator } from "../../ui/separator";
import { FaLinkedin } from "react-icons/fa";
import { FaPinterestSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { letUsHelpYou, quickLinks, userPolicy } from "../../../constants";
import { useGetAllSubcategoryQuery } from "../../../redux/slice/CategoryApiSlice";

interface Subcategory {
  categoryId: number;
  id: number;
  subcategoryName: string;
}

const Footer = () => {
  const { data: AllSubCategories } = useGetAllSubcategoryQuery("subCategoriesList", { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true, })

  //console.log(AllSubCategories);


  return (
    <footer className=" bg-gradient-to-b from-white to-green-800 border-t-2 mt-8 xl:px-2 bg-gray-400">
      <div className="wrapper lg:justify-between lg:flex-row flex-col flex  gap-10">

        <div className="flex flex-col gap-2">
          <h1 className="text-md font-bold">CONTACT US</h1>
          {letUsHelpYou.map((qlink: any) => {
            return <Link to={qlink.path} key={qlink.name} className="text-md font-normal">
              {qlink.name}
            </Link>
          })}
        </div>
        <div className="flex flex-col gap-2">
          <h1 className="text-md font-bold">USER POLICY</h1>
          {userPolicy.map((qlink: any) => {
            return <Link to={qlink.route} key={qlink.name} className="text-md font-normal">
              {qlink.name}
            </Link>
          })}
        </div>
        <div className="flex flex-col  gap-2">
          <h1 className="text-md font-bold">SOCIAL MEDIA</h1>
          <div className="flex gap-4 ">
           <Link to={"https://www.youtube.com/watch?v=wjIf88ElLDc"} target="_blank"> <IoLogoYoutube size={28} color="red" /></Link>
           <Link to={"https://www.instagram.com/sakhithehouseofkanchiweaves?igsh=OGNlZ2hpOW04MHR4"} target="_blank">   <FaInstagram size={28} color="brown" /></Link>
           <Link to={"https://www.facebook.com/share/txXM6JQhWQKm5aDF/?mibextid=qi2Omg"} target="_blank">  <FaFacebookF size={28} color="blue"/></Link>
           <Link to={"https://www.pinterest.com/"} target="_blank">   <FaPinterestP  size={28} color="red" /></Link>
  
          </div>
        </div>
      </div>

    {/*   <div className="wrapper">
        <div className="flex flex-col gap-2">
        <h1 className="text-md font-bold ">OUR LOCATIONS</h1>
        <div className="grid lg:grid-cols-4 grid-cols-2 max-sm:grid-cols-1  max-sm:gap-4 gap-6">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9050.558093852946!2d78.39541799703622!3d17.49362029590286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91e1ac4501d3%3A0xb076e2c8aa8b8c0b!2sSakhi%20the%20house%20of%20kanchi%20weaves%20-%20KPHB!5e0!3m2!1sen!2sin!4v1723111662431!5m2!1sen!2sin" title="location"  loading="lazy" className="w-full h-48"></iframe>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121769.32115679418!2d78.319182530274!3d17.49360321392088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b9942049067%3A0x5d4ef996f30d16c0!2sSakhi%20the%20house%20of%20kanchi%20weaves!5e0!3m2!1sen!2sin!4v1723112347486!5m2!1sen!2sin" title="location"  loading="lazy" className="w-full h-48"></iframe>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15307.410510520327!2d80.568411!3d16.4323094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a35f1286aba421d%3A0x67841f58a9895a0c!2sSakhi%20the%20house%20of%20Kanchi%20weaves%20-%20Wholesale%20Weavers!5e0!3m2!1sen!2sin!4v1723203581432!5m2!1sen!2sin" title="location" loading="lazy" className="w-full h-48"></iframe>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15317.68239466!2d80.4365368!3d16.3014397!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a75001d9eff97%3A0x9efd33bfcb263ee0!2sSakhi.The%20house%20of%20Kanchi%20weaves.Saree%20Shop.!5e0!3m2!1sen!2sin!4v1723203605893!5m2!1sen!2sin" title="location" loading="lazy" className="w-full h-48"></iframe>
        </div>
        </div>

      </div> */}
      <div className="wrapper">
        <div className="flex flex-col gap-2">
          <h1 className="text-md font-bold">POPULAR SEARCHES</h1>
          <div className="flex flex-wrap items-center">
            {AllSubCategories?.result.length > 0 && AllSubCategories?.result.map((subCat: Subcategory) => {
              return <Link to={`/subcategory/${subCat.id}`} key={subCat.id} style={{fontSize:"10px"}} className="text-gray-500 font-medium">
                {subCat.subcategoryName}{'|'}
              </Link>
            })}
          </div>
        </div>

      </div>
      <div className="wrapper flex justify-center  w-full">
        <div className="flex flex-col text-center gap-3 ">
          <h1 className="text-md font-bold">COMPLETELY SAFE AND SECURE PAYMENT METHOD</h1>
          <p className="text-sm font-semibold">We accept Netbanking, all major cards.</p>
          <div className="flex gap-4 flex-wrap justify-center ">
            <img src="/assets/payments/paypal.png" alt="paypal"  className="h-[40px]" />
            <img src="/assets/payments/Razorpay.png" alt="Razorpay"  className="h-[40px]" />
            <img src="/assets/payments/mastercard.png" alt="MasterCard" className="h-[40px]" />
            <img src="/assets/payments/rupay.png" alt="Rupay"  className="h-[40px]" />
          </div>
        </div>
      </div>
      <Separator className="border border-gray-400" />
      <div className=" wrapper  flex flex-center  p-5 text-center sm:flex-row">
        {/*  <Link to='/'>
          <img 
            src="/assets/logo/CPATitleLogoSmall.png"
            alt="logo"
            width={100}
            height={60}
          />
        </Link> */}

        <p>@copyright 2024 All Rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer