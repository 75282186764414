import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { Separator } from "../../ui/separator";
/*   import NavItems from "./NavItems" */
import { FaBars } from "react-icons/fa";
import { SubNavigation } from "./NavItems";
import Search from "./Search";
import { useGetAllSubcategoryQuery, useGetSubcategoryQuery } from "../../../redux/slice/CategoryApiSlice";
import { useState } from "react";
import { Button } from "../../../components/ui/button";
import { subHeaderLinks } from "../../../constants";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { Link } from "react-router-dom";

const MobileNav = () => {
  return (
    <nav className="md:hidden sticky top-0">
    <Sheet key="left">
      <SheetTrigger className="align-middle">
        <FaBars size="26px" color="black"/>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-6 bg-white md:hidden overflow-auto" side="left">
        <img 
          src="/assets/logo/sakhi_logo.png"
          alt="logo"
          width={128}
          height={38}
        />
        <Separator className="border border-gray-100" />
        <div>
          {/* <Search/> */}
          {/* <SubNavigation/> */}
          <MobileNavItems/>
        </div>
      </SheetContent>
    </Sheet>
  </nav>
  );
};


const MobileNavItems = () => {
  const [catId, setCatId] = useState<number | null>(null);
  const { data: AllSubCategories } = useGetAllSubcategoryQuery("subCategoriesList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const [showSubC, setShowSubC] = useState(false);

  const handleCategory = (categoryId: number) => {
    if (catId === categoryId) {
      setShowSubC(!showSubC); 
    } else {
      setCatId(categoryId);
      setShowSubC(true); 
    }
  };

  return (
    <div>
      {subHeaderLinks.map((links) => {
        const hasSubCategories =
          links.categoryId === catId && showSubC && AllSubCategories?.result?.length > 0;

        return (
          <div key={links.categoryId}>
            <Button variant={"link"} onClick={() => handleCategory(links.categoryId)} className="flex justify-between hover:text-green-600 lg:text-green-900 text-md font-medium items-start">
             <span>{links.label}</span> 
              <span>
                {hasSubCategories ? (
                  <FaChevronUp size={18} className="ml-4" />
                ) : (
                  <FaChevronDown size={18} className="ml-4"/>
                )}
              </span>
            </Button>
            {hasSubCategories && (
              <div className="ml-6 flex flex-col gap-2"> 
               <Link key={0} to={`/category/${catId}`} className="py-1 text-sm">
               {links.label}
                  </Link>
              {AllSubCategories.result
                .filter((sub: any) => sub.categoryId === catId)
                .map((sub: any) => (
                  <Link key={sub.id} to={`/subcategory/${sub.id}`} className="text-sm">
                    {sub.subcategoryName}
                  </Link>
                ))}
            </div>
          )}
        </div>
      );
    })}
        <Link to={`/shopbyprice/10000`} className={`ml-4 my-2 hover:text-green-600 lg:text-green-900 flex gap-4 items-center`}>BUDGET SAREES
        </Link>
  </div>
);
};



export default MobileNav;


