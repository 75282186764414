import { createSlice } from "@reduxjs/toolkit"

 const authDetailsSlice = createSlice({
  name: 'authdetails',
  initialState:{auth:""},
  reducers: {
    setCredentials: (state) => {
        state.auth= "credentials set in local storage"
    },
    removeCredentials(state){
      state.auth=""
  }
  },
})

export const { setCredentials,removeCredentials } = authDetailsSlice.actions


export default authDetailsSlice.reducer