import { IMGURL } from '../../../constants/appConfig';
import { useGetPopularProductsQuery } from '../../../redux/slice/ProductsApiSlice'
import { RootState } from "@/redux/store";
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const PopularProducts = () => {
    const { currency } = useSelector((state: RootState) => state.counterCurrency)
    const { data: products, isError, isLoading, isSuccess, error } = useGetPopularProductsQuery("productsList", {
      pollingInterval: 60000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true
    })
  
    let content
  
    if (isLoading) {
      content = (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      );
    }
  
    if (isError) content = <p>{products?.result?.message}</p>
    if (isSuccess && products?.result?.length>0) {
      content = (<section className="wrapper bgcolorgold bg-dotted-pattern bg-contain py-3 px-10  font-display">
        <div className="flex justify-center py-1">
          <h5 className="text-4xl">Popular Sales</h5>
        </div>
        <div className="pt-3 grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-1 w-full max-sm:gap-5 gap-3 xl:gap-2">
       
         {products?.result?.map((newcollection:any) => (
          <div
            key={newcollection.id}
            className="max-w-[400px] h-[400px] mx-2 "
          >
            <Link to={`/newcollection/${newcollection.id}`}>
            <div className="overflow-hidden ">
            <img  src={`${IMGURL}${newcollection.thumbnail}`}
              /* <img  src={newcollection.url} */
                alt={newcollection.productName}
                 className="w-full h-[320px] max-sm: transform hover:scale-110 transition-transform duration-300"/>
            </div>
            </Link>
            <div className="flex justify-center p-4 flex-col items-center gap-2">
              <p className="text-md font-medium line-clamp-2 overflow-ellipsis custom-product-name">
                {newcollection.productName}
              </p>
              <p className="text-md font-medium flex gap-1 items-center custom-price">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(currency === "USD" ? newcollection.priceUSD : newcollection.priceINR)}
              </p>
            </div>
          </div>
        ))}
          
        </div>
      </section>)
    }
    return <>{content}</>
}

export default PopularProducts