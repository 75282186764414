
import { Button } from "../../ui/button"
import { Link } from "react-router-dom"
import Download from "./Download"


/* const Explore = () => {
  return (
    <div className=" bgcolorgold">
      <div className="relative wrapper-newprice">
        <img
        src="/assets/videoImg.jpg"
        alt="explore"
        className="w-full h-[400px] "/>
        <div className="absolute bottom-[10%]  text-white left-[18%] md:w-[50%] flex flex-col gap-3 z-20">
             <Button size="lg" asChild className='button headermenu w-full  font-semibold text-center sm:w-fit'><Link to="/explore">
              Book Now
            </Link></Button>
        </div>
        </div>
    </div>
  )
} */
const Explore = () => {
  return (<>
    <div className="grid grid-cols-1 gap-4 mt-4 wrapper font-display ">
      <div className="relative ">
        <img
        src="/images/VideoShopping.jpg" 
        alt="explore"
        className="w-full rounded-lg sm:block hidden"/>
        <img
        src="/images/VideoShoppingM.jpg" 
        alt="explore"
        className="w-full rounded-lg  max-sm:block hidden"/>
        <div className="absolute lg:bottom-[30%] bottom-[10%] text-green-900 left-[5%] md:w-[50%]  flex flex-col gap-3 z-20">
          <p className="text-xl lg:text-4xl font-bold">Video Shopping</p>
             <Button size="lg" asChild className='headermenu w-full rounded-xl md:h-[50px] h-[40px] md:text-lg font-semibold text-lg  text-center sm:w-fit'><Link to="/explore">
              Book Now
            </Link></Button>
        </div>
        </div>
    </div>
   {/*  <div className="grid grid-cols-1 gap-4 mt-4 wrapper-newprice md:hidden">
      <div className="relative ">
        <img
        src="/images/Mobilevideo.jpg" 
        alt="explore"
        className="w-full rounded-lg "/>
        <div className="absolute top-[10%]  text-white left-[5%]  flex flex-col gap-3 z-20">
          <p className="text-xl font-semibold">Video Shopping</p>
             <Button size="sm" asChild className='headermenu w-[100px] font-medium rounded-lg  text-sm  text-center'><Link to="/explore">
              Book Now
            </Link></Button>
        </div>
        <div className="absolute bottom-[10%]  text-white right-[4%]  flex flex-col gap-3 z-20">
        <Link to="/"><img src="/assets/payments/playstore.jpeg"alt="playstore" className="h-9 w-28 rounded-lg"/></Link>
        <Link to="/"><img src="/assets/payments/apple.png" alt="applestore" className="h-9 w-28 rounded-lg"/></Link>
        </div>
        </div>

    </div> */}
    </>)
}

export default Explore