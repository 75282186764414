import { FaChevronLeft } from 'react-icons/fa6';
import { Card } from '../../../components/ui/card';
import React from 'react'
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';

const PagenavigationCarousel = () => {
  return (
    <section className="bgcolorgold bg-dotted-pattern bg-contain">
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        draggable
        focusOnSelect={false}
        infinite={true}
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
      rewind={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024
          },
          items: 1
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0
          },
          items: 1
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464
          },
          items: 1
        }
      }}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={true}
        slidesToSlide={1}
        swipeable
      >
 <img src="/assets/caraousel/sakhi_topbanner1.png" alt="Sakhi Sarees" className='w-full xl:h-[580px]'/>
       <img src="/assets/caraousel/sakhi_topbanner2.png" alt="Sakhi Sarees" className='w-full xl:h-[580px]'/>
       <img src="/assets/caraousel/sakhi_topbanner3.png" alt="Sakhi Sarees" className='w-full xl:h-[580px]'/>

      </Carousel>

  </section>
  )
}

export default PagenavigationCarousel
